.Group .card {
  border-color: lightgray;
}

.Group .card-header{
  background: none !important;
  border-color: lightgray;
}

.btn-primary {
  background-color: #0062cc;
}

.badge-dead {
  background-color: black;
  color: white;
}

.Service {
  border: none;
  margin: 1px;
  padding: 5px;
}

.dropdown-toggle-no-arrow > .dropdown-toggle:after { content: none }